<template>
  <GModal name="modal" class="modal modal-payment" :class="{ cta: is_payment_success }">
    <div slot="body">
      <div id="vm-cola-payment" class="vm-cola-payment">
        <template v-if="is_payment_success">
          <div class="top-container">
            <h1>Enjoy your beauties!</h1>
            <h2>
              Yuk, ambil beauties kamu di bagian bawah <br />
              mesin Vending Machine ini, Bestie!
            </h2>

            <img src="/static/img/icons/payment-cta-icon.svg" alt="payment-cta-icon" />
            <div class="arrow-down-cta-container">
              <img src="/static/img/icons/arrow-down-cta-icon.svg" alt="payment-cta-icon" />
            </div>
          </div>
        </template>
        <template v-else>
          <div class="top-container">
            <img class="back" src="/static/img/icons/icon-left-arrow-black.svg" @click="$emit('confirm')" />
            <img class="gpn-icon" src="/static/img/vending-machine/icon-qris.svg" alt="QRIS | Sociolla" />
          </div>
          <div class="payment-container">
            <h2>SOCIOLLA</h2>
            <h4>NMID : ID2020018187712</h4>
            <h4 class="reference_code">Order ID : {{ reference_code }}</h4>
            <h4 class="price">
              Total Belanja <span>{{ total_price | currencyRp }}</span>
            </h4>
            <div class="qrcode">
              <img v-if="qrcode" :src="qrcode" alt="QRCode Payment Sociolla Vending Machine" />
              <span v-else>Waiting for QRCode, Please wait...</span>
            </div>
            <div class="payment-refresh">
              <div v-if="is_loading_fetch_detail">
                <div id="loaderspinwrap" class="loaderspinwrap"><div class="loaderspin"></div></div>
              </div>
              <template v-else>
                <div class="label">
                  Tekan tombol di bawah ini jika layar belum <br />
                  berubah saat pembayaran telah berhasil.
                </div>
                <button @click.prevent="onClickRefreshPaymentStatus">Verify Payment</button>
              </template>
            </div>
            <h5>Cara melakukan pembayaran dengan QRIS</h5>
            <ul class="list-pembayaran">
              <li>
                <img src="/static/img/vending-machine/pembayaran-1-new.svg" alt="Pembayaran 1 | Sociolla" />
                <div class="caption">Buka aplikasi pembayaran digital dengan scan QR Code di atas.</div>
              </li>
              <li>
                <img src="/static/img/vending-machine/pembayaran-2-new.svg" alt="Pembayaran 2 | Sociolla" />
                <div class="caption">Pastikan nama merchant yang muncul adalah a/n Sociolla.</div>
              </li>
              <li>
                <img src="/static/img/vending-machine/pembayaran-3-new.svg" alt="Pembayaran 3 | Sociolla" />
                <div class="caption">Pastikan nominal pembayaran sesuai dengan total belanja kamu.</div>
              </li>
            </ul>
          </div>
        </template>
      </div>
    </div>
  </GModal>
</template>

<script>
import axios from 'axios';
import helpers from '@/mixins/helpers';
import ls from '@/components/helpers/local-storage';
import SocketIO from 'socket.io-client';

const Config = require('~/config/default.env').default;
let socket;

export default {
  name: 'ModalPayment',
  mixins: [helpers],
  props: {
    totalQty: {
      type: Number,
      default: 0,
    },
    orderResponse: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      order_detail: '',
      callback_counting: 0,
      callback_detail: '',

      is_payment_success: false,
      is_loading_fetch_detail: false,
    };
  },

  computed: {
    isLogin() {
      return this.$store.state.QRcode.isLoggedIn;
    },
    user() {
      return this.$store.state.QRcode.user;
    },
    total_price() {
      if ('reference_code' in this.orderResponse) {
        return this.orderResponse.total_paid;
      }

      return 0;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    qrcode() {
      if (this.callback_detail) {
        if (
          this.callback_detail.payment_response &&
          this.callback_detail.payment_response.actions &&
          this.callback_detail.payment_response.actions.length
        ) {
          return this.callback_detail.payment_response.actions[0].url;
        } else if (this.callback_detail.payment_response && this.callback_detail.payment_response.final_payment_code) {
          return this.callback_detail.payment_response.final_payment_code;
        } else if (this.callback_detail.payment_code) {
          return this.callback_detail.payment_code;
        } else if (this.callback_detail.actions) {
          return this.callback_detail.actions[0].url;
        }
      }
    },
    reference_code() {
      if ('reference_code' in this.orderResponse) {
        return this.orderResponse.reference_code;
      }

      return '-';
    },
    latest_cart_id() {
      return this.$store.state.QRcode.latest_cart_id;
    },
  },

  created() {
    if ('reference_code' in this.orderResponse) {
      this.resetData();
      this.initSocket();
    }
  },

  mounted() {
    if ('reference_code' in this.orderResponse) {
      this.getCallback();
    }
  },

  methods: {
    async onClickRefreshPaymentStatus() {
      this.getOrderDetail(true);
    },
    async backAndCancelPayment() {
      const url = Config.MS_CART_API_URL + '/carts/reorder';
      const [loc, token] = await Promise.all([Cookies.get('store_location_id'), Cookies.get('token')]);

      if (loc) {
        const headers = {
          'SOC-PLATFORM': 'vending-machine',
          store_id: loc,
        };

        if (token) {
          headers['Authorization'] = `Bearer ${token}`;
        }

        const is_guest = await ls.get('is_guest');

        if (is_guest) {
          headers['is-guest'] = true;
          headers['guest-checkout'] = true;
        }

        const response = await axios.post(url, { cart_id: this.latest_cart_id }, { headers: headers });

        if (response.status === 200) {
          await Promise.all([
            this.$store.commit('SET_CART_ID', response.data.data._id),
            this.$store.dispatch('fetchCurrentUserCart', this),
          ]);

          this.$emit('close');
        }
      }
    },
    async getCallback() {
      const loc = Cookies.get('store_location_id');
      const token = Cookies.get('token');
      const id_order = this.orderResponse._id;

      if (loc && id_order) {
        const headers = {
          'SOC-PLATFORM': 'vending-machine',
          store_id: loc,
        };
        if (token) {
          headers['Authorization'] = `Bearer ${token}`;
        }

        await axios
          .post(
            `${Config.MS_PAYMENT_API_URL}/payments/callback`,
            {
              id_order: id_order,
            },
            {
              headers: headers,
            }
          )
          .then((res) => {
            this.callback_detail = res.data.data;
            this.getOrderDetail();
          })
          .catch((err) => {
            this.getOrderDetail();
          });
      }
    },
    async getOrderDetail(isManual = false) {
      this.callback_counting++;
      const loc = Cookies.get('store_location_id');
      const token = Cookies.get('token');
      const id_order = this.orderResponse._id;
      if (loc && id_order) {
        const headers = {
          'SOC-PLATFORM': 'vending-machine',
          store_id: loc,
        };
        if (token) {
          headers['Authorization'] = `Bearer ${token}`;
        }

        const is_guest = await ls.get('is_guest');
        if (is_guest) {
          headers['is-guest'] = true;
          headers['guest-checkout'] = true;
        }

        this.is_loading_fetch_detail = true;
        await axios
          .get(`${Config.MS_ORDER_API_URL}/order/${id_order}`, {
            headers: headers,
          })
          .then((res) => {
            const res_order = res.data.data;
            this.order_detail = res_order;
            if (
              res_order.soco_state != 'Awaiting Veritrans payment' &&
              res_order.soco_state != 'Waiting payment' &&
              (res_order.soco_state == 'Delivered' || res_order.soco_state == 'Payment accepted')
            ) {
              this.redirectPayment(res_order.reference_code);
            } else if (res_order.soco_state == 'Cancelled' || res_order.soco_state == 'Payment Failure') {
              this.$router.push({ path: '/vmcola/order/payment-failed' });
              socket?.off('payment_status_update');
            } else if (res_order.soco_state.toLowerCase() == 'waiting payment' && isManual) {
              this.$toasted.global.show('Pembayaran sedang diproses');
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.is_loading_fetch_detail = false;
          });
      }
    },
    redirectPayment(reference = '', to = '') {
      const reference_code =
        this.order_detail && this.order_detail.reference_code
          ? this.order_detail.reference_code
          : this.callback_detail && this.callback_detail.reference_code
          ? this.callback_detail.reference_code
          : '';
      if (reference && reference_code ? reference_code == reference : true) {
        const host = window.location && window.location.hostname;
        const is_local = host ? host.indexOf('localhost') > -1 || host.indexOf('uat') > -1 : false;
        this.$router.push({
          path: to,
          query: {
            total: this.orderResponse.total_paid,
            id_order: this.orderResponse._id,
            reference_code: this.orderResponse.reference_code,
            payment_method: 'GOPAY',
            'disable-bucket': is_local,
          },
        });
      }
    },
    resetData() {
      this.order_detail = '';
      this.callback_counting = 0;
      this.callback_detail = '';
    },

    async initSocket() {
      const is_guest = await ls.get('is_guest');

      socket = SocketIO(`${Config.MS_SOCKET_API_URL}/notifications`, { transports: ['websocket'] });
      socket.on('connect', () => {
        const room = !is_guest ? this.user && this.user._id : this.orderResponse._id;
        socket.emit('join', room);
      });

      const context = this;

      socket.on('payment_status_update', (data) => {
        if (data.order_id == this.orderResponse._id) {
          this.order_detail = this.orderResponse._id;

          if (data.status != 'Payment Pending') {
            if (data.status == 'Payment Accepted') {
              context.is_payment_success = true;

              setTimeout(() => {
                this.redirectPayment(data.reference_code, '/vmcola/order/payment-success');
              }, 5000);
            } else if (data.status == 'Payment Failure' || data.status == 'Cancelled') {
              this.redirectPayment(data.reference_code, '/vmcola/order/payment-failed');
            }
            socket.off('payment_status_update');
          } else {
            if (this.order_detail) {
              if (
                this.order_detail.soco_state != 'Awaiting Veritrans payment' &&
                this.order_detail.soco_state != 'Waiting payment' &&
                (this.order_detail.soco_state == 'Delivered' || this.order_detail.soco_state == 'Payment accepted')
              ) {
                context.is_payment_success = true;

                setTimeout(() => {
                  this.redirectPayment(data.reference_code, '/vmcola/order/payment-success');
                }, 5000);
              }
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './payment';
</style>
